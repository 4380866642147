.chat-container {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: 1fr;
  height: 100vh;
}

.left {
  position: relative;
}

.right {
  background-color: #fafafa;
  position: relative;
}

/* ===== MENU ===== */

/* === CONVERSATIONS === */

.discussions {
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  /* the 120px is for 60px left top height + 60px for left top filters */
  height: calc(100vh - 120px);
  overflow-y: scroll;
}

.discussion:hover {
  background-color: whitesmoke !important;
}

.discussions .discussion {
  width: 100%;
  height: 90px;
  background-color: #fafafa;
  border-bottom: solid 1px #e0e0e0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.left-top {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-top-filters {
  border-top: 0.01px solid #ccc;
  border-bottom: 0.01px solid #ccc;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-top-filters div {
  padding: 0 20px;
  border-radius: 50px;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
  cursor: pointer;
}

.left-top > .searchbar input {
  border-radius: 25px;
  padding: 6px 14px;
  border: none;
  outline: none;
}

.searchbar {
  padding: 0 20px;
  border-radius: 50px;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-chat {
  height: 60px;
  /* height: 10vh; */
  background-color: #1f384b;
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.header-chat .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  color: #fff;
}

.discussions .message-active {
  border-right: solid 5px #1f384b;
}

.discussions .discussion .photo {
  margin-left: 20px;
  display: block;
  width: 45px;
  height: 45px;
  background: #e6e7ed;
  border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.online {
  position: relative;
  top: 30px;
  left: 35px;
  width: 13px;
  height: 13px;
  background-color: #8bc34a;
  border-radius: 13px;
  border: 3px solid #fafafa;
}

.desc-contact {
  height: 43px;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.discussions .discussion .name {
  margin: 0 0 0 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 11pt;
  color: #515151;
}

.discussions .discussion .message {
  margin: 6px 0 0 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 9pt;
  color: #515151;
}

.timer {
  max-width: 60px;
  margin-left: 15%;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  padding: 3px 8px;
  color: #bbb;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
}

.unread {
  position: absolute;
  top: 36%;
  right: 1px;
  color: #bbb;
  background-color: #00bfb3;
  color: #fff;
  border: 1px solid #e5e5e5;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

/* === RIGHT SIDE MESSAGING === */

.messages-chat {
  padding: 25px 35px;
  height: calc(100vh - calc(110px));
  /* 110px is from -> 60vh for chat header and 10px height for input area height */
  overflow-y: scroll;
}

/* width */
.messages-chat::-webkit-scrollbar,
.discussions::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.messages-chat::-webkit-scrollbar-track,
.discussions::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.messages-chat::-webkit-scrollbar-thumb,
.discussions::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.messages-chat::-webkit-scrollbar-thumb:hover,
.discussions::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.messages-chat .message {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.messages-chat .message .photo,
.header-chat .photo {
  display: block;
  width: 45px;
  height: 45px;
  background: #e6e7ed;
  border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.messages-chat .text {
  position: relative;
  margin: 0 35px;
  background-color: #00bfb3;
  /* padding: 15px; */
  padding: 10px 10px 18px 10px;
  min-width: 80px;
  max-width: 380px;
  border-radius: 10px;
  font-size: 13px;
  line-height: 19px;
  color: #fff;
}

.text > span {
  position: absolute;
  color: white;
  font-size: 0.6rem;
  bottom: 0px;
  right: 10px;
}

.text-only {
  margin-left: 45px;
}

.time {
  font-size: 10px;
  color: lightgrey;
  margin-bottom: 10px;
  margin-left: 85px;
}

.response-time {
  float: right;
  margin-right: 40px !important;
}

.response {
  float: right;
  margin-right: 0px !important;
  margin-left: auto; /* flexbox alignment rule */
}

.response .text {
  background-color: #1f384b !important;
}

.input-area {
  position: absolute;
  display: flex;
  bottom: 0px;
  width: 100%;
  height: 50px;
  padding: 5px 0;
  background-color: #1f384b;
}

.input-area > div {
  flex: 0.05;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-area > div:last-child {
  flex: 0.05;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-area input,
.input-area input:focus {
  flex: 1;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 0 13px;
}
